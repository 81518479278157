.o-cms-product-list {
    margin-bottom: 100px;

    .--mobile {
        display: flex;
        flex-wrap: wrap;
    }
    .--mobile .product-widget__image {
        width: percentage(calc(1 / 3));
    }
    .--mobile .product-widget__data {
        width: percentage(calc(2 / 3));
    }
    .--mobile .m-product-variants {
        width: 100%;
        text-align: center;
    }

    &__product-list-grid {
        display: grid;
        overflow: hidden;

        @include media(xxl) {
            grid-template-columns: repeat(5, 20%);
        }
    }

    &__product-widget-container {
        @include border($where: bottom, $color: $grey--medium);
        padding: ($space * 2) 0 ($space * 3);
        margin: 0 auto;
        width: 100%;
        transform: translateY(1px);
    }

    &__toolbar-container {
        padding: 0 0 ($space * 6) 0;
        margin: 0 0 ($space * 6) $space * 2;
        border-bottom: 1px solid $grey--medium;
        display: flex;
    }

    &__filter {
        display: none;
    }

    &__mobile-filter-button {
        height: 46px;
        background-color: #fff;
        font-size: $font-size--medium;
        line-height: $line-height--large;
        width: 100%;

        .icon--filter-icon {
            font-size: $font-size--medium;
        }
    }

    &__modal-panel {
        width: 100% !important;
        padding: ($space * 2) $space 0 $space;
        position: relative;
        max-width: none;
        left: auto;

        & .modal__close::after {
            font-size: $font-size--large;
            color: $grey--darker;
        }

        &  .panel__body {
            padding-top: $space * 16 !important;
        }

        .modal__close--top {
            right: $space;
        }
    }
}

;@import "sass-embedded-legacy-load-done:429";