.picture-block {
    &__content {
        margin-bottom: $space * 8;
        margin-left: calc(-1*(100vw - 100%) / 2);
        margin-right: calc(-1*(100vw - 100%) / 2);

        &.-black {
            background-color: $grey--darker;
            color: white;
        }

        &.-right {
            flex-direction: row-reverse;
        }

        &.-dark-grey {
            background-color: $grey--light;
            color: $grey--darker;
        }

        .picture-block__text-container {
            width: 100%;
            margin: auto;
        }

        &.-clickable {
            cursor: pointer;
        }
    }

    &__image {
        margin: 0;
        width: auto;

        &-small {
            aspect-ratio: 1/1;
        }

        &-big {
            aspect-ratio: 3/2;
        }

        &-small,
        &-big {
            padding-bottom: 0;
        }
    }

    &__text {
        margin-top: $space * 3;

        p {
            margin-bottom: $space * 4;
        }

        ul > li {
            position: relative;
            padding-left: $space * 4;

            em {
                font-style: italic;
            }

            &::before {
                position: absolute;
                content: '■';
                left: 0;
                color: $grey--darker;
            }
        }
    }

    &__text-container {
        padding: $space * 4;
    }

    &__button {
        &-container {
            margin-top: $space * 3;
        }

        &.-dark-grey {
            border-color: $grey--darker;
        }

        .-black {
            color: white;
            border-color: white;
        }

        .-dark-grey {
            color: $grey--darker;
        }
    }
}

.picture-block__text.-black {
    ul > li {
        &::before {
            color: white;
        }
    }
}

;@import "sass-embedded-legacy-load-done:732";