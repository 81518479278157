@include media(md) {
    .third-picture-block {
        &__content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
        }

        &__image {
            width: calc(100% / 3);

            .o-full-picture-block {
                padding: 0;

                &__text {
                    margin: 0;
                }
            }
        }

        &__text {
            width: calc((100% / 3) * 2);
            padding-left: $space * 8;
            margin-top: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:505";