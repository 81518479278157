/* ------------------------------------ *\
    HOMEPAGE
\* ------------------------------------ */
.homepage {
    &-content--bottom {
        padding-top: $space;
    }

    &__about-tab-content {
        max-height: 500px;
        overflow: hidden;

        &.collapsed {
            max-height: 0;
            padding: 0;
        }
    }

    &__list-placeholder {
        background: $grey--lighter;
        height: 270px;

        .fa-spin {
            color: $theme-color;
            font-size: 30px;
            left: calc(50% - 21px);
            position: relative;
            top: calc(50% - 21px);
        }
    }
}

@include media(xxl) {
    .homepage {
        &-content--top {
            padding-left: $space * 4;
        }
    }
}

;@import "sass-embedded-legacy-load-done:170";