.table-of-contents {
    @include grow-outside-container;
    background-color: $grey--light;
    margin-bottom: $space * 14;

    &__headline {
        padding: ($space * 8) 0 ($space * 4);
    }

    &__ul {
        padding-bottom: $space * 7;
    }

    &__li {
        margin-bottom: $space;

        &::before {
            position: relative;
            content: '■';
            font-family: $font-family--font-awesome;
            left: 0;
            color: $theme-color;
            padding-right: $space;
        }

        & a {
            text-decoration: none;
        }
    }
}

;@import "sass-embedded-legacy-load-done:474";