.related-stories {
    @include grow-outside-container;
    background-color: $grey--light;

    &__container {
        padding: 0 ($space * 8);
    }

    &__headline {
        padding: ($space * 8) 0;
        text-align: center;
        @include text-variant(extra);
    }

    &__items {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: $space * 8;
        padding-bottom: $space * 6;
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-basis: 100%;
        margin-bottom: $space * 8;

        &-title {
            @include line-clamp(2);
            @include text-variant(medium-plus);
            margin: ($space * 2) 0;
        }

        &--last {
            display: none;
        }
    }

    &__image {
        aspect-ratio: 16/9;
        object-fit: cover;
        width: 100%;
    }

    &__footer {
        font-size: $font-size--small;
        color: $grey--darker;
    }

    &__pre-header {
        color: $blue;
    }
}

;@import "sass-embedded-legacy-load-done:554";